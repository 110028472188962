
    import {Component, Vue} from 'vue-property-decorator';

    @Component<Contact>({
        metaInfo()
        {
            return {
                title: 'Kontakt',
                titleTemplate: '%s | Půjčovna Haryků',
            };
        },
    })
    export default class Contact extends Vue
    {
    }
